import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {initializeApp} from 'firebase/app';
import {onAuthStateChanged,getAuth,getIdToken} from 'firebase/auth';
import moment from 'moment-timezone'
import VueMoment from 'vue-moment'
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';

const socket = io('https://adlandekrany.t1s.pl',{
  autoConnect: false


});


const firebaseConfig = {
  apiKey: "AIzaSyBBxXegob-glNhvcOD7TFc-QldtvG5pjYE",
  authDomain: "platforma-4ce3f.firebaseapp.com",
  databaseURL: "https://platforma-4ce3f.firebaseio.com",
  projectId: "platforma-4ce3f",
  storageBucket: "platforma-4ce3f.appspot.com",
  messagingSenderId: "520168869018",
  appId: "1:520168869018:web:5dc3450b10bd8a872e2323",
  measurementId: "G-7GMWNZP5BN"
};



 const app = initializeApp(firebaseConfig)

 console.log(app);



 const auth = getAuth();

console.log(auth.currentUser);

 onAuthStateChanged(auth, user => {
   // Check for user status

   console.log(user);
   if(user){
    console.log(user);
    socket.io.opts.query = { token: user.accessToken };
    socket.open();

   }
 });

 socket.onerror = function(err) {

console.log(err)
console.log("Socket error. Exchaning auth data")

getIdToken(auth.currentUser).then(token=>{

  console.log(token)
  socket.io.opts.query = { token: token };


  })

};

socket.disconnect = function(err) {
  console.log("Socket disconnected exchanging auth data")
  console.log(err)

 getIdToken(auth.currentUser).then(token=>{

  console.log(token)
  socket.io.opts.query = { token: token };


  })


  };

 


  Vue.use(VueMoment, {
    moment,
})


Vue.use(VueAxios, axios)
Vue.use(VueSocketIOExt, socket);
Vue.config.productionTip = false
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
