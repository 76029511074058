<template>
   <v-app id="inspire">
      <v-content>
         <v-container fluid fill-height v-bind:style="{ backgroundImage: 'url(https://cdn.pixabay.com/photo/2015/07/02/09/52/interior-design-828545_960_720.jpg)', backgroundSize: 'cover' }" >

<v-row   >



<v-col offset="8" lg="4"    class="white rounded-l-xl px-10 justify-center align-center d-flex" style="height:100vh;" :elevation="3">

          <v-container>
                     <div class="text-h2 text-left  pt-3 pb-2"><b>Tech in Shot</b></div>

                     <div class="text-overline px-3 text-left  pb-10">AdLand interaktywne ekrany</div>
                    
                    
                    
              
                        <v-form class="px-4" >
                           <v-text-field
                              v-model ="email"
                              label="Login"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              v-model ="password"

                              label="Hasło"
                              type="password"
                           ></v-text-field>
                        </v-form>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn   x-large  rounded  dark class="px-10 " color="primary" @click = "login();" >Zaloguj</v-btn>
                     </v-card-actions>
         
          </v-container>
</v-col>
</v-row>
         </v-container>
      </v-content>
   </v-app>
</template>

<script>

import {signInWithEmailAndPassword,getAuth,onAuthStateChanged} from 'firebase/auth';

export default {
   name: 'Login',
   props: {
      source: String,
   },

  data() { 
  return { 
    email: '', 
    password: '', 
  }; 
},
 mounted(){
 const auth = getAuth();

 onAuthStateChanged(auth, user => {
   // Check for user status

   if(user){

this.$router.push('/' )



   }


 });
     
 },
methods:{


 login() {
     const auth = getAuth();


 signInWithEmailAndPassword(auth,this.email, this.password)
 .then(() => {
   this.$router.push('/');
 })
 .catch(error => {
   alert(error.message);
 });

  },

}
};
</script>

<style></style>
