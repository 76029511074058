<template>
  <div>
   
   
    <v-dialog v-model="dialog" persistent max-width="600px">
     
      <v-card>
        <v-card-title>
          <span class="headline">Nowy materiał</span>
        </v-card-title>

        <v-divider> </v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              :rules="nazwaRules"
              prepend-icon="mdi-format-title"
              solo
              class="mt-4"
              v-model="materialnazwa"
              :disabled="loadingmaterial"
              label="Nazwa materiału"
              required
            ></v-text-field>

            <v-textarea
              :rules="opisRules"
              prepend-icon="mdi-information-outline"
              solo
              auto-grow
              full-width
              v-model="materialopis"
              :disabled="loadingmaterial"
              label="Opis materiału"
              required
            ></v-textarea>
            <v-file-input
              prepend-icon="mdi-file-image"
              solo
              :rules="plikRules"
              :disabled="loadingmaterial"
              v-model="file"
              ref="file"
              label="Plik z materiałem"
            ></v-file-input>
          </v-form>
       
        </v-card-text>

        <v-divider class="mt-3"></v-divider>

        <v-card-actions class="pb-4 mt-4">
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadingmaterial"
            class="white--text px-4"
            color="deep-purple accent-4"
            @click="utworzmaterial()"
          >
            Zapisz
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog = false;
              $refs.form.reset();
            "
            >Zamknij</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>



   
    <v-dialog v-model="edytujdialog" persistent max-width="600px">
     
      <v-card>
        <v-card-title>
          <span class="headline">Edytuj materiał</span>
        </v-card-title>

        <v-divider> </v-divider>
        <v-card-text>
          <v-form ref="formedycja">
            <v-text-field
              :rules="nazwaRules"
              prepend-icon="mdi-format-title"
              solo
              class="mt-4"
              v-model="edytujnazwa"
              :disabled="loadingmaterialedycja"
              label="Nazwa materiału"
              required
            ></v-text-field>

            <v-textarea
              :rules="opisRules"
              prepend-icon="mdi-information-outline"
              solo
              auto-grow
              full-width
              v-model="edytujopis"
              :disabled="loadingmaterialedycja"
              label="Opis materiału"
              required
            ></v-textarea>
           
          </v-form>
       
        </v-card-text>

        <v-divider class="mt-3"></v-divider>

        <v-card-actions class="pb-4 mt-4">
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadingmaterialedycja"
            class="white--text px-4"
            color="deep-purple accent-4"
            @click="edytujmaterial()"
          >
            Zapisz
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="
              edytujdialog = false;
              $refs.formedycja.reset();
            "
            >Zamknij</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>




    <v-list-item two-line class="d-flex justify-space-between">
      <v-list-item-content>
        <v-list-item-title class="headline"
          >Dostępne materiały</v-list-item-title
        >
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon v-on:click="dialog = true">
          <v-icon color="grey lighten-1">mdi-plus</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>

    <div class="mojeekrany mt-4">
      <v-text-field
        class="mx-4 mt-4"
        label="Nazwa materiału"
        required
        v-model="search"
      ></v-text-field>

      <v-data-table
        class="mx-4 mt-2"
        :items="filteredList"
        locale="pl"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :headers="headers"
        show-expand
      >
        <template v-slot:item.klient="{ item }">
          {{ item.klient }}
          <v-tooltip bottom v-if="item.info != null && item.info != ''">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-2" color="primary" dark v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span>{{ item.info }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.czas_dodania="{ item }">
          {{ item.czas_dodania | moment("DD.MM.YYYY") }}
        </template>

        <template v-slot:item.typ="{ item }">
          {{ typ(item.typ) }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="pr-4 pl-4 pb-4"
            style="shadow: 0"
            :colspan="headers.length"
          >
            <p class="mx-5 my-5" v-text="item.opis"></p>

          
            <v-divider class="mt-6 mx-4"></v-divider>

        
            <v-card-text>

                <div class="d-flex flex-row justify-space-between">


<div>



              <v-chip @click="pobierz()" class="mr-2">
                <v-icon left>mdi-cloud-download-outline</v-icon>
                Pobierz materiał

              </v-chip>


</div>
<div>
   <v-chip @click="id_edytuj = item.id; edytujopis = item.opis; edytujnazwa = item.nazwa;edytujdialog = true;" class="mr-2">
                <v-icon left>mdi-circle-edit-outline</v-icon>
                Edytuj

              </v-chip>

   <v-chip @click="id_usun = item.id; usun();" class="mr-2">
                <v-icon left>mdi-delete</v-icon>
                Usuń

              </v-chip>
</div>


</div>




            </v-card-text>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { onAuthStateChanged, getAuth, getIdToken } from "firebase/auth";

export default {
  name: "Studio",
  mounted() {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      // Check for user status

      if (user == null) {
        console.log("user is null");
      }
    });

    this.zaladujmaterialy();
    this.zaladujekrany();
  },

  computed: {
    filteredList() {
      return this.materialy.filter((post) => {
        return post.nazwa.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    procent: function () {
      if (this.ekrany.length > 0) {
        return (this.ekrany_online.length / this.ekrany.length) * 100;
      } else {
        return 0;
      }
    },
  },
  watch: {
    expanded: function (val) {
      if (val != null && val.length > 0) {
        console.log(val);
        this.zaladujmaterial();

        this.tytul = val[0].nazwa;
      }
      //tutaj załaduj zawartość
    },
  },
  data: () => ({
    edytujdialog:false,
    edytujnazwa:"",
    edytujopis:"",
    id_usun:null,
    id_edytuj:null,
    nazwaRules: [
      (v) => !!v || "Name ekranu jest wymagana",
      (v) => v.length > 3 || "Nazwa musi być dłuższa niż 3 znaku",
    ],
    opisRules: [(v) => !!v || "Opis ekranu jest wymagany"],
    plikRules: [(v) => !!v || "Plik jest wymagany"],

    materialy: [],
    utworzonoekran: false,
    nowylogin: "",
    nowehaslo: "",
    tytul: "",
    search: "",
    singleExpand: true,
    expanded: [],
    value: [],
    loadingmaterial: false,
    loadingmaterialedycja: false,

    file: null,
    materialopis: "",
    headers: [
      {
        text: "Nazwa kampani",
        align: "left",
        sortable: true,
        value: "nazwa",
      },

      {
        text: "Data utworzenia",
        align: "left",
        sortable: true,
        value: "czas_dodania",
      },
      {
        text: "Typ",
        align: "left",
        sortable: true,
        value: "typ",
      },
    ],

    materialnazwa: "",
    materialtyp: 2,
    ekrantelefon: "",
    ekranopis: "",
    ekrany: [],
    ekranadres: "",
    uszkodzone_ekrany: [],
    ekrany_online: {
      If45YsRvnlbwu0EvJiJUrIaDy1i1: { instances: 3 },
    },
    links: ["Ekrany", "Studio", "Pomoc"],
    dialog: false,
    dialogprzypisz: false,
  }),

  methods: {
    forceFileDownload(response, nazwa) {
      try {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", nazwa); //or any other extension

        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.log(e);
      }
    },

    async pobierz() {
      const auth = getAuth();

      const token = await getIdToken(auth.currentUser);

      axios({
        method: "get",
        url: `https://adlandekrany.t1s.pl/${this.expanded[0]["adres"]}`,
        responseType: "arraybuffer",

        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          this.forceFileDownload(
            response,
            `Materiał ${
              this.expanded[0]["nazwa"] +
              "." +
              this.expanded[0]["adres"].split(".").pop()
            }`
          );
        })
        .catch(() => {
          this.fileerror = true;
          this.pobieranie = false;
        });
    },

     async usun() {

           if(confirm('Czy napewno chcesz usunąć materiał?')){



console.log("usuwam material");
       try{
      const auth = getAuth();

      const token = await getIdToken(auth.currentUser);

       var postData = {
          id: this.id_usun,
        };

        let axiosConfig = {
          headers: {
            Authorization: token,
          },
        };

        await axios.post(
          "https://adlandekrany.t1s.pl/materialy/usun",
          postData,
          axiosConfig
        );

        this.zaladujmaterialy();

      } catch (e) {
        console.log(e);
      }

     }
    },




    async zaladujekrany() {
      const auth = getAuth();

      const token = await getIdToken(auth.currentUser);

      let axiosConfig = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(
        "https://adlandekrany.t1s.pl/ekrany",
        axiosConfig
      );
      this.ekrany = data.ekrany;
      this.ekrany_online = data.online;
      console.log(data);
    },
    async zaladujmaterial() {
      try {
        const auth = getAuth();

        const token = await getIdToken(auth.currentUser);

        var postData = {
          material: this.expanded[0]["id"],
        };

        let axiosConfig = {
          headers: {
            Authorization: token,
          },
        };

        var { data } = await axios.post(
          "https://adlandekrany.t1s.pl/material",
          postData,
          axiosConfig
        );

        this.value = data;
      } catch (e) {
        console.log(e);
      }
    },
    typ(t) {
      if (t == 1) {
        return "Grafika";
      } else if (t == 2) {
        return "Film";
      } else {
        return "Strona internetowa";
      }
    },

    async zaladujmaterialy() {
      const auth = getAuth();

      const token = await getIdToken(auth.currentUser);

      let axiosConfig = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(
        "https://adlandekrany.t1s.pl/studio",
        axiosConfig
      );
      this.materialy = data;
      console.log(data);
    },
    async aktualizujekran() {
      console.log(this.value);

      try {
        const auth = getAuth();

        const token = await getIdToken(auth.currentUser);
        var postData = {
          material: this.expanded[0]["id"],
          ekrany: JSON.stringify(this.value),
        };

        let axiosConfig = {
          headers: {
            Authorization: token,
          },
        };

        await axios.post(
          "https://adlandekrany.t1s.pl/aktualizujstan",
          postData,
          axiosConfig
        );

        this.value = [];

        this.zaladujmaterial();
      } catch (e) {
        console.log(e);
      }
    },
    async utworzmaterial() {
      if (this.$refs.form.validate()) {
        this.loadingmaterial = true;

        try {
          const auth = getAuth();

          const token = await getIdToken(auth.currentUser);

          let formData = new FormData();
          formData.append("file", this.file);

          formData.append("nazwa", this.materialnazwa);
          formData.append("opis", this.materialopis);

          formData.append("typ", this.materialtyp);

          let axiosConfig = {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          };

          await axios.post(
            "https://adlandekrany.t1s.pl/nowymaterial",
            formData,
            axiosConfig
          );

          this.loadingmaterial = false;
          this.materialtyp = 1;
          this.dialog = false;
          this.$refs.form.reset();

          this.zaladujmaterialy();
        } catch (e) {
          this.loadingmaterial = false;

          console.log(e);
        }
      }
    },

     async edytujmaterial() {
      if (this.$refs.formedycja.validate()) {
        this.loadingmaterialedycja = true;

        try {
          const auth = getAuth();

          const token = await getIdToken(auth.currentUser);


console.log(this.id_edytuj);
  var postData = {


        nazwa: this.edytujnazwa,
          opis: this.edytujopis,
        id: this.id_edytuj
  };
          let axiosConfig = {
            headers: {
              Authorization: token,
            },
          };

          await axios.post(
            "https://adlandekrany.t1s.pl/edytujmaterial",
            postData,
            axiosConfig
          );

          this.loadingmaterialedycja = false;
          this.edytujdialog = false;
          this.$refs.formedycja.reset();

          this.zaladujmaterialy();
        } catch (e) {
          this.loadingmaterialedycja = false;

          console.log(e);
        }
      }
    },
  },

  sockets: {},
};
</script><style >
.shad {
  border-radius: 10px;
  box-shadow: -5px 5px 21px #c3bfc6, 5px -5px 21px #ffffff;
}
</style>