<template>


  <v-app id="inspire">




<v-snackbars max-width="450px" :messages.sync="messages" :timeout="8000" color="grey" bottom right>

  <template v-slot:default="{ message }">
    <v-list-item  v-if="message.typ ==1"
   
      >
        <v-list-item-avatar>
          <v-icon
            dark
            color="green"
                        class="white lighten-1"

          >
mdi-lan-connect       </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{message.name}}</v-list-item-title>

          <v-list-item-subtitle>Nawiązano połączenie z ekranem </v-list-item-subtitle>
        </v-list-item-content>

      
      </v-list-item>

       <v-list-item  v-if="message.typ ==4"
   
      >
        <v-list-item-avatar>
          <v-icon
            dark
            color="green"
                        class="white lighten-1"

          >
mdi-cached      </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{message.name}}</v-list-item-title>

          <v-list-item-subtitle>Ustawiono <b> {{message.nazwa}} </b></v-list-item-subtitle>
        </v-list-item-content>

      
      </v-list-item>


  <v-list-item  v-if="message.typ ==3"
   
      >
        <v-list-item-avatar>
          <v-icon
            dark
            color="green"
                        class="white lighten-1"

          >
mdi-cloud-check-outline       </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{message.name}}</v-list-item-title>

          <v-list-item-subtitle>Materiał <b>{{message.nazwa}} </b>został zapisany </v-list-item-subtitle>
        </v-list-item-content>

      
      </v-list-item>
        <v-list-item  v-if="message.typ ==2"
   
      >
        <v-list-item-avatar>
          <v-icon
            dark
                        class="white lighten-1"

            color = "red"
          >
mdi-alert       </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{message.name}}</v-list-item-title>

          <v-list-item-subtitle>Utracono łączność z ekranem</v-list-item-subtitle>
        </v-list-item-content>

      
      </v-list-item>



  </template>



</v-snackbars>




 <v-dialog v-model="dialog" persistent max-width="600px">
     
      <v-card>
        <v-card-title>
          <span class="headline">Nowy ekran</span>
        </v-card-title>


        <v-divider>
        </v-divider>
        <v-card-text>
      
          
                <v-text-field  class="mt-4"
                
                v-model = "ekrannazwa"
                :disabled="loadingekran"
                label="Nazwa klienta" required></v-text-field>

             

                <v-text-field
                
                                v-model = "ekranopis"
               :disabled="loadingekran"

                 label="Opis ekranu" required></v-text-field>
                <v-text-field
                
                                v-model = "ekrantelefon"
              :disabled="loadingekran"

                label="Telefon" required></v-text-field>

             
            
        
 <v-text-field
                
                                v-model = "ekranadres"
              :disabled="loadingekran"

                label="Lokalizacja ekranu" required></v-text-field>




        </v-card-text>
    

<v-divider class="mt-3"></v-divider>

        <v-card-actions class="pb-4 mt-4">

          <v-spacer></v-spacer>
           <v-btn 
     :loading="loadingekran"

        
        class="white--text px-4"
        color="deep-purple accent-4"

        @click="utworzekran();"
      >
        Zapisz  
      </v-btn>

          <v-btn color="blue darken-1" text @click="dialog = false">Zamknij</v-btn>
        </v-card-actions>


          














      </v-card>
    </v-dialog>




    <v-app-bar
      app
      color="white"
      flat
    >

      <v-container class="py-0 fill-height">
        <v-avatar
          class="mr-10"
          color="grey darken-1"
          size="32"
        ></v-avatar>

           <v-btn @click="$router.push('/')"
          text
        >
          Ekrany
        </v-btn>
           <v-btn @click="$router.push('/studio')"
          text
        >
          Studio
        </v-btn>
        
    <v-btn @click="$router.push('/statystyka')"
          text
        >
          Statystyka
        </v-btn>
        <v-spacer></v-spacer>

      
      </v-container>
    </v-app-bar>



    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col cols="3" >
            <v-sheet rounded="lg" >
<div class="d-flex justify-center">

<v-progress-circular
      :rotate="-90"
      :size="100"
      class="my-8"
      :width="15"
      :value="procentdzialania"
      color="green"
    >
     {{iloscaktywnych}}/{{iloscekranow}}
    </v-progress-circular>

</div>



     <v-list-item  v-if="$socket.connected"
   
      >
        <v-list-item-avatar>
          <v-icon
            class="primary lighten-1"
            dark
          >
mdi-lan-connect       </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Poprawne połączenie</v-list-item-title>

          <v-list-item-subtitle>Komunikacja na żywo</v-list-item-subtitle>
        </v-list-item-content>

      
      </v-list-item>

  <v-list-item  v-if="$socket.disconnected"
   
      >
        <v-list-item-avatar>
          <v-icon
            class="red lighten-1"
            dark
          >
mdi-lan-disconnect          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Brak połączenia</v-list-item-title>

          <v-list-item-subtitle>Odśwież by zobaczyć zmiany</v-list-item-subtitle>
        </v-list-item-content>

      
      </v-list-item>

           












<v-divider class="mt-5"></v-divider>


             <v-list-item  @click="wyloguj();"
   
      >
        <v-list-item-avatar>
          <v-icon
            
          >
mdi-logout-variant       </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Wyloguj</v-list-item-title>

        </v-list-item-content>

      
      </v-list-item>

            </v-sheet>
          </v-col>

          <v-col cols="9">
         
    <router-view/>




          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import VSnackbars from "v-snackbars"

import {onAuthStateChanged,getAuth,getIdToken,signOut} from 'firebase/auth';


  export default {
       name: 'Home',
       metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Adland Alcon Interactive Screens',
    
    },
        components:{
    "v-snackbars": VSnackbars
  },

 mounted(){
 const auth = getAuth();

 onAuthStateChanged(auth, user => {
   // Check for user status

   if(user == null){

this.$router.push('/login' )



   }


 });


 this.zaladujekrany();
     
 },
    data: () => ({
      messages:[],
      ekrany: [],
      loadingekran:false,
      ekrannazwa:'',
      ekrantelefon: '',
      ekrany_online:{},
      ekranopis:'',
      ekranadres: '',
      uszkodzone_ekrany:[],
      lacznosc_ekrany:[],
      links: [
        'Ekrany',
        'Studio',
        
      ],
      dialog:false,
      sortowanie:1
    }),
computed:{

iloscaktywnych(){

return Object.keys(this.ekrany_online).length

},
iloscekranow(){

return this.ekrany.length

},
procentdzialania(){

if(this.iloscekranow>0){
return this.iloscaktywnych/this.iloscekranow*100

}else{

  return 0;
}

}

},
    methods:{
async wyloguj(){

const auth = getAuth();
await signOut(auth);


this.$router.push('/login');

},
     async zaladujekrany(){

 const auth = getAuth();


 const token = await getIdToken(auth.currentUser);





    let axiosConfig = {
      headers: {
          'Authorization': token,
        
      }
    };




  const { data } = await axios.get("https://adlandekrany.t1s.pl/ekrany",axiosConfig);
  this.ekrany = data.ekrany;
  console.log(data)


      },
},

    sockets:{

screen_connected(data){

this.messages.push({"name":data,typ:1})
},
screen_disconnected(data){
this.messages.push({name:data,typ:2})


},
are_online(data){


  this.ekrany_online = data;

}





    }
  }
</script>