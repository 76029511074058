var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Nowy materiał")])]),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-text-field',{staticClass:"mt-4",attrs:{"rules":_vm.nazwaRules,"prepend-icon":"mdi-format-title","solo":"","disabled":_vm.loadingmaterial,"label":"Nazwa materiału","required":""},model:{value:(_vm.materialnazwa),callback:function ($$v) {_vm.materialnazwa=$$v},expression:"materialnazwa"}}),_c('v-textarea',{attrs:{"rules":_vm.opisRules,"prepend-icon":"mdi-information-outline","solo":"","auto-grow":"","full-width":"","disabled":_vm.loadingmaterial,"label":"Opis materiału","required":""},model:{value:(_vm.materialopis),callback:function ($$v) {_vm.materialopis=$$v},expression:"materialopis"}}),_c('v-file-input',{ref:"file",attrs:{"prepend-icon":"mdi-file-image","solo":"","rules":_vm.plikRules,"disabled":_vm.loadingmaterial,"label":"Plik z materiałem"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),_c('v-divider',{staticClass:"mt-3"}),_c('v-card-actions',{staticClass:"pb-4 mt-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text px-4",attrs:{"loading":_vm.loadingmaterial,"color":"deep-purple accent-4"},on:{"click":function($event){return _vm.utworzmaterial()}}},[_vm._v(" Zapisz ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false;
              _vm.$refs.form.reset();}}},[_vm._v("Zamknij")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.edytujdialog),callback:function ($$v) {_vm.edytujdialog=$$v},expression:"edytujdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edytuj materiał")])]),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"formedycja"},[_c('v-text-field',{staticClass:"mt-4",attrs:{"rules":_vm.nazwaRules,"prepend-icon":"mdi-format-title","solo":"","disabled":_vm.loadingmaterialedycja,"label":"Nazwa materiału","required":""},model:{value:(_vm.edytujnazwa),callback:function ($$v) {_vm.edytujnazwa=$$v},expression:"edytujnazwa"}}),_c('v-textarea',{attrs:{"rules":_vm.opisRules,"prepend-icon":"mdi-information-outline","solo":"","auto-grow":"","full-width":"","disabled":_vm.loadingmaterialedycja,"label":"Opis materiału","required":""},model:{value:(_vm.edytujopis),callback:function ($$v) {_vm.edytujopis=$$v},expression:"edytujopis"}})],1)],1),_c('v-divider',{staticClass:"mt-3"}),_c('v-card-actions',{staticClass:"pb-4 mt-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text px-4",attrs:{"loading":_vm.loadingmaterialedycja,"color":"deep-purple accent-4"},on:{"click":function($event){return _vm.edytujmaterial()}}},[_vm._v(" Zapisz ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.edytujdialog = false;
              _vm.$refs.formedycja.reset();}}},[_vm._v("Zamknij")])],1)],1)],1),_c('v-list-item',{staticClass:"d-flex justify-space-between",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline"},[_vm._v("Dostępne materiały")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-plus")])],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"mojeekrany mt-4"},[_c('v-text-field',{staticClass:"mx-4 mt-4",attrs:{"label":"Nazwa materiału","required":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"mx-4 mt-2",attrs:{"items":_vm.filteredList,"locale":"pl","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"headers":_vm.headers,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.klient",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.klient)+" "),(item.info != null && item.info != '')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.info))])]):_vm._e()]}},{key:"item.czas_dodania",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.czas_dodania,"DD.MM.YYYY"))+" ")]}},{key:"item.typ",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.typ(item.typ))+" ")]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"pr-4 pl-4 pb-4",staticStyle:{"shadow":"0"},attrs:{"colspan":headers.length}},[_c('p',{staticClass:"mx-5 my-5",domProps:{"textContent":_vm._s(item.opis)}}),_c('v-divider',{staticClass:"mt-6 mx-4"}),_c('v-card-text',[_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('div',[_c('v-chip',{staticClass:"mr-2",on:{"click":function($event){return _vm.pobierz()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-download-outline")]),_vm._v(" Pobierz materiał ")],1)],1),_c('div',[_c('v-chip',{staticClass:"mr-2",on:{"click":function($event){_vm.id_edytuj = item.id; _vm.edytujopis = item.opis; _vm.edytujnazwa = item.nazwa;_vm.edytujdialog = true;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-circle-edit-outline")]),_vm._v(" Edytuj ")],1),_c('v-chip',{staticClass:"mr-2",on:{"click":function($event){_vm.id_usun = item.id; _vm.usun();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Usuń ")],1)],1)])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }