<template>
  <div>
    
    




    <v-list-item two-line class="d-flex justify-space-between">
      <v-list-item-content>
        <v-list-item-title class="headline">Statystyka</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
    
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>

    <div class="mojeekrany mt-4">
      <v-row class="flex-wrap flex-row fill-height">
        <v-col
          v-for="miesiac in miesiace"
          :key="miesiac.czas"
          lg="4"
          xl="3"
          class="shrink"
        >
          <div
            class="
              white
              shad
              fill-height
              d-flex
              flex-column
              justify-space-between
            "
            @click="
             pobierzmiesiac(miesiac.czas)
            "
          >

       



            <div>

              <div
                class="ml-5 mr-3 text-subtitle-1 pt-2 pb-1"
               
              >{{ miesiac.czas | moment("MMMM YYYY")}}</div>
            </div>

            <div>
              <div
                class="text-body-2 grey--text ml-5 mb-3 mr-3"
                
              >Kliknięć {{miesiac.ilosc}}</div>

           

       

            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { onAuthStateChanged, getAuth, getIdToken } from "firebase/auth";

export default {
  name: "Statystyka",
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Adland Alcon Interactive Screens',
    
    },
  mounted() {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      // Check for user status

      if (user == null) {
        console.log("user is null");


        
      }
    });

    this.zaladujmiesiace();
  },

 

  data: () => ({
    miesiace: [],
    
 
  
  }),

  methods: {
 forceFileDownload(response,nazwa){


   try{
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url

     
      link.setAttribute('download', nazwa+'.xlsx') //or any other extension
     


      document.body.appendChild(link)
      link.click()

   }catch(e){
  console.log(e)


   }




    },
    


async pobierzmiesiac(miesiac){


      const auth = getAuth();

      const token = await getIdToken(auth.currentUser);
 axios({
        method: 'post',
        url: "https://adlandekrany.t1s.pl/statystyka_miesiaca",
        responseType: 'arraybuffer',
        
        data:{
        czas: miesiac
 

        },

           headers: {
          'Authorization': `${token}`,
        
      }
      })
      .then(response => {
        
        this.forceFileDownload(response,"Raport_Aktywnosci")
        
      })
      .catch(() => console.log('error occured'))




},
    async zaladujmiesiace() {
      const auth = getAuth();

      const token = await getIdToken(auth.currentUser);

      let axiosConfig = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(
        "https://adlandekrany.t1s.pl/statystyka",
        axiosConfig
      );
      this.miesiace = data;

      console.log(data);
    },

   
   



  
  }

 
};
</script><style >
.shad {
  border-radius: 10px;
  box-shadow: -5px 5px 21px #c3bfc6, 5px -5px 21px #ffffff;
}
</style>