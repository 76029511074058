import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Screens from '../views/Screens.vue'
import Studio from '../views/Studio.vue'
import Statystyka from '../views/Statystyka.vue'

import {getAuth} from 'firebase/auth';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to,from,next)=>{
      if (!getAuth().currentUser) {
        next();
      } else {
        next({
          path: '/',
        });
      }

    }

   
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/',
        component: Screens
      },
      
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: '/studio',
        component: Studio
      },

       
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: '/statystyka',
        component: Statystyka
      }


    ],
    beforeEnter: (to,from,next)=>{
      if (getAuth().currentUser) {
        next();
      } else {
        next({
          path: '/login',
        });
      }

    }
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




export default router
