<template>
  <div>
   
   
   
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Nowy ekran</span>
        </v-card-title>

        <v-divider> </v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              class="mt-4"
              prepend-icon="mdi-format-title"
              solo
              :rules="nazwaRules"
              v-model="ekrannazwa"
              :disabled="loadingekran"
              label="Nazwa ekranu"
              required
            ></v-text-field>

            <v-textarea
              prepend-icon="mdi-information-outline"
              solo
              :rules="opisRules"
              auto-grow
              full-width
              v-model="ekranopis"
              :disabled="loadingekran"
              label="Opis ekranu i uwagi"
              required
            ></v-textarea>

            <v-text-field
              prepend-icon="mdi-phone-in-talk-outline"
              solo
              :rules="telefonRules"
              v-model="ekrantelefon"
              :disabled="loadingekran"
              label="Telefon"
              required
            ></v-text-field>

              <v-text-field
              
              prepend-icon="mdi-web"
              solo
              :rules="urlRules"
              v-model="ekranurl"
              :disabled="loadingekran"
              label="Strona internetowa"
              required
            ></v-text-field>




            <v-textarea
              prepend-icon="mdi-map-marker-outline"
              solo
              :rules="adresRules"
              auto-grow
              full-width
              v-model="ekranadres"
              :disabled="loadingekran"
              label="Lokalizacja ekranu"
              required
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider v-if="utworzonoekran" class="mt-3"></v-divider>

        <div class="text-overline ml-5 mt-3" v-if="utworzonoekran">
          Dane dostępowe
        </div>
        <v-list-item v-if="utworzonoekran">
          <v-list-item-avatar>
            <v-icon class="grey lighten-1" dark> mdi-monitor-lock </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="nowylogin"></v-list-item-title>

            <v-list-item-subtitle v-text="nowehaslo"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action> </v-list-item-action>
        </v-list-item>

        <v-divider class="mt-3"></v-divider>

        <v-card-actions class="pb-4 mt-4">
          <v-spacer></v-spacer>
          <v-btn
          v-if="!utworzonoekran"
            :loading="loadingekran"
            class="white--text px-4"
            color="deep-purple accent-4"
            @click="utworzekran()"
          >
            Zapisz
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog = false;
              $refs.form.reset();
            "
            >Zamknij</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>




      
    <v-dialog v-model="edycjadialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edytuj ekran</span>
        </v-card-title>

        <v-divider> </v-divider>
        <v-card-text>
          <v-form ref="formedycja">
            <v-text-field
              class="mt-4"
              prepend-icon="mdi-format-title"
              solo
              :rules="nazwaRules"
              v-model="edycjanazwa"
              :disabled="edycjaloading"
              label="Nazwa ekranu"
              required
            ></v-text-field>

            <v-textarea
              prepend-icon="mdi-information-outline"
              solo
              :rules="opisRules"
              auto-grow
              full-width
              v-model="edycjaopis"
              :disabled="edycjaloading"
              label="Opis ekranu i uwagi"
              required
            ></v-textarea>

            <v-text-field
              prepend-icon="mdi-phone-in-talk-outline"
              solo
              :rules="telefonRules"
              v-model="edycjatelefon"
              :disabled="edycjaloading"
              label="Telefon"
              required
            ></v-text-field>

              <v-text-field
              
              prepend-icon="mdi-web"
              solo
              :rules="urlRules"
              v-model="edycjaurl"
              :disabled="edycjaloading"
              label="Strona internetowa"
              required
            ></v-text-field>




            <v-textarea
              prepend-icon="mdi-map-marker-outline"
              solo
              :rules="adresRules"
              auto-grow
              full-width
              v-model="edycjaadres"
              :disabled="edycjaloading"
              label="Lokalizacja ekranu"
              required
            ></v-textarea>
          </v-form>
        </v-card-text>

        
  

        <v-divider class="mt-3"></v-divider>

        <v-card-actions class="pb-4 mt-4">
          <v-spacer></v-spacer>
          <v-btn
            :loading="edycjaloading"
            class="white--text px-4"
            color="deep-purple accent-4"
            @click="edytujekran()"
          >
            Zapisz
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="
              edycjadialog = false;
              $refs.formedycja.reset();
            "
            >Zamknij</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


<v-dialog
      v-model="dialog_kolejka"
      scrollable
      max-width="500px"
    >
     
      <v-card>
        <v-card-title>Dodaj materiał</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px;">
          <v-radio-group
            v-model="kolejka_wybor"
            column
          >
            <v-radio v-for = "material in do_wyboru" :key = "material.adres"
              :label="material.nazwa"
              :value="material.id"
            ></v-radio>
           
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
      <v-card-actions class="pb-4 mt-4">
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadingkolejka"
            class="white--text px-4"
            color="deep-purple accent-4"
            @click="dodajkolejka()"
          >
            Dodaj
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog_kolejka = false;
            "
            >Zamknij</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>





    <v-dialog   v-model="danedialog" persistent max-width="90vw">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center" >

        <div class="d-flex align-center">
          <span class="headline text-wrap mr-6" v-text="danenazwa"></span>


 <v-icon v-if="!ekrany_online[wybranyuid]" color="red"
                >mdi-lan-disconnect </v-icon
              >

 <v-icon v-else color="green"
                >mdi-lan-connect </v-icon
              >
</div>
          <v-btn @click="danedialog=false;" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

      
      
        <v-divider></v-divider>

        <div class="d-flex flex-row">



 <div   v-if="wybranyzrzut != 'https://adlandekrany.t1s.pl/null'"  class="col">
            <img style="width: 100%" :src="wybranyzrzut" />






          </div>


      <div class="col">


<div class="d-flex justify-space-between align-center">
            <div class="text-overline ml-5  mb-4 mt-1">Kolekcja filmów</div>




            <v-btn @click="dialog_kolejka=true" icon><v-icon>mdi-plus</v-icon></v-btn>



</div>        

  <v-list-item v-if="kolejka.length == 0" >
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark>mdi-file-find-outline </v-icon>
              </v-list-item-avatar>
              

              <v-list-item-content>
                <v-list-item-title>Nie znaleziono filmów</v-list-item-title>
                <v-list-item-subtitle>Przypisz materiały zapisane w studiu.</v-list-item-subtitle>

              </v-list-item-content>



            </v-list-item>


  <v-list-item v-for="kolej in kolejka" :key="kolej.id">
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-video </v-icon>
              </v-list-item-avatar>
              

              <v-list-item-content>
                <v-list-item-title v-text="kolej.nazwa"></v-list-item-title>

              </v-list-item-content>

  <v-list-item-action>
          <v-btn icon @click="usunkolejka(kolej.id)">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>


            </v-list-item>



      </div>
          <div class="col">
            <div class="text-overline ml-5 mt-1">dane urządzenia</div>
           

           <v-list-item>
              <v-list-item-avatar>
                <v-icon class="" >mdi-navigation-variant-outline</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-wrap" v-text="daneadres"></v-list-item-title>

                <v-list-item-subtitle>Adres urządzenia</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action> </v-list-item-action>
            </v-list-item>

            <v-list-item>
   <v-list-item-avatar>
                <v-icon class="" >mdi-phone</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title  class="text-wrap"  v-text="danetelefon"></v-list-item-title>

                <v-list-item-subtitle>Telefon do punktu</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action> </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="" >mdi-cellphone-information</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title  class="text-wrap"  v-text="daneopis"></v-list-item-title>

                <v-list-item-subtitle>Uwagi do urządzenia</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action> </v-list-item-action>
            </v-list-item>


            <div class="text-overline ml-5 mt-1">KONSOLA</div>


  <v-list-item  v-for="log in log_table" :key="log.czas">
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-console-line </v-icon>
              </v-list-item-avatar>
              

              <v-list-item-content>
                <v-list-item-title  class="text-wrap" v-text="log.txt"></v-list-item-title>
                <v-list-item-subtitle  class="text-wrap">{{log.czas | moment("DD.MM.YYYY HH:mm")}}</v-list-item-subtitle>

              </v-list-item-content>




            </v-list-item>





<v-divider></v-divider>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="" >mdi-monitor-lock </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title  class="text-wrap"  v-text="danelogin"></v-list-item-title>

                <v-list-item-subtitle  class="text-wrap"  v-text="danehaslo"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action> </v-list-item-action>
            </v-list-item>

     <v-list-item>
              <v-list-item-avatar>
                <v-icon class="" >mdi-web</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>

               <v-text-field v-if="edytujurl"
              class="mt-4"
              solo
              v-model="nowyurl"
              label="Adres url"
              required
            ></v-text-field>


                <v-list-item-title v-if="!edytujurl" class="text-wrap" v-text="daneurl"></v-list-item-title>

                <v-list-item-subtitle v-if="!edytujurl" >Wyświetlany adres url</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action> 
              
              <v-btn icon v-if="!edytujurl" @click="edytujurl = true; nowyurl = daneurl;">
              <v-icon>mdi-circle-edit-outline</v-icon>
              
              </v-btn>

               <v-btn icon v-if="edytujurl" @click="zapiszurl()">
              <v-icon>mdi-content-save</v-icon>
              
              </v-btn>


              </v-list-item-action>
            </v-list-item>





              <v-list-item v-if= "wybranyzrzutczas" >
                <v-list-item-avatar>
                  <v-icon >mdi-archive-clock-outline </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{wybranyzrzutczas | moment("DD.MM.YYYY HH:mm")}}</v-list-item-title>

                  <v-list-item-subtitle
                  
                  >Czas wygenerowania zrzutu ekranu</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

<div class="d-flex  mx-4 align-center justify-space-between mb-5">

<div>


 <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
      
          <v-btn class=" ml-4 mt-5"
              color="primary"
              :loading="refreshing"
            @click="odswiez_ekran()"
              fab
              small
              dark
              v-bind="attrs"
          v-on="on"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>


      </template>
      <span>Odświez zawartość ekranu</span>
    </v-tooltip>




             <v-tooltip     v-if="!wybranypotrzeba"
 top>
      <template v-slot:activator="{ on, attrs }">
       
         <v-btn 
            class=" ml-4 mt-5"
              fab
               v-bind="attrs"
          v-on="on"
              small
              dark
              color="primary"
              @click="zglos_zrzut_ekranu()"
              ><v-icon>mdi-camera</v-icon></v-btn
            >


      </template>
      <span>Wykonaj zrzut ekranu</span>
    </v-tooltip>



           
 <v-tooltip v-else top>
      <template v-slot:activator="{ on, attrs }">
        

         <v-btn 
            
               fab
              small
              dark
    v-bind="attrs"
          v-on="on"

               class=" ml-4 mt-5"
              color="secondary"

              
              ><v-icon>mdi-timer-sand-complete</v-icon></v-btn
            >


      </template>
      <span>Oczekiwanie na zrzut ekranu</span>
    </v-tooltip>
           


            <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
      



<v-btn class=" ml-4 mt-5"
v-bind="attrs"
          v-on="on"
              color="primary"
            @click="edycjadialog = true; edycjanazwa = danenazwa; edycjaopis = daneopis; edycjatelefon = danetelefon; edycjaurl = daneurl; edycjaadres = daneadres;"
              fab
              small
              dark
            >
              <v-icon>mdi-fountain-pen-tip</v-icon>
            </v-btn>


      </template>
      <span>Edytuj dane ekranu</span>
    </v-tooltip>



    
</div>



 <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        

         <v-btn class=" ml-4 mt-5"
              color="red"
            @click="usunekran();"
              fab
              v-bind="attrs"
          v-on="on"
              small
              dark
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
      </template>
      <span>Usuń ekran</span>
    </v-tooltip>



           




</div>


            
        </div>





         
      
      
      
      
      
      
      
      
      
        </div>

     
      </v-card>
    </v-dialog>

    <v-list-item two-line class="d-flex justify-space-between">
      <v-list-item-content>
        <v-list-item-title class="headline">Moje ekrany</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>





<div class="d-flex flex-row align-center">




         <v-btn-toggle class="mr-2"
        v-model="sortowanie"
        borderless
      >
        <v-btn :value= "1">
        
          <v-icon class="px-3" >
           mdi-lan-check
          </v-icon>
        </v-btn>
 <v-btn :value= "3">
        
          <v-icon class="px-3" >
          mdi-lan-disconnect
          </v-icon>
        </v-btn>
        <v-btn :value="2">
          
          <v-icon class="px-3">
         mdi-order-alphabetical-ascending
          </v-icon>
        </v-btn>
      </v-btn-toggle>


        <v-btn
          icon
          v-on:click="
            dialog = true;
            utworzonoekran = false;
          "
        >
          <v-icon color="grey lighten-1">mdi-plus</v-icon>
        </v-btn>

        </div>

      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>

    <div class="mojeekrany mt-4">
      <v-row class="flex-wrap flex-row fill-height">
        <v-col
          v-for="ekran in posegregowaneekrany"
          :key="ekran.uid"
          lg="4"
          xl="3"
          class="shrink"
        >
          <div
            class="
              white
              shad
              fill-height
              d-flex
              flex-column
              justify-space-between
            "
            @click="
              danedialog = true;
              danenazwa = ekran.nazwa;
              danelogin = ekran.login;
              danehaslo = ekran.haslo;
              nowyurl = ekran.url;
              edytujurl = false;
              daneadres = ekran.adres;
              daneurl = ekran.url;
              danetelefon = ekran.telefon;
              daneopis = ekran.opis;
              wybranyuid = ekran.uid;pobierz_ekran()
            "
          >
            <div class="d-flex justify-end">
              <v-icon v-if="!ekrany_online[ekran.uid]" color="red"
                >mdi-alert</v-icon
              >

              <div
                v-if="ekrany_online[ekran.uid]"
                class="
                  text-overline
                  d-flex
                  flex-row
                  justify-end
                  mx-3
                  green--text
                  text--darken-5
                "
              >
                POŁĄCZONO
              </div>

              <div
                v-if="!ekrany_online[ekran.uid]"
                class="text-overline d-flex flex-row justify-end red--text mx-3"
              >
                brak połączenia
              </div>
            </div>

            <div>
              <v-divider></v-divider>

              <div
                class="ml-5 mr-3 text-subtitle-1 pt-2 pb-1"
                v-text="ekran.nazwa"
              ></div>
            </div>

            <div>
              <div
                class="text-body-2 grey--text ml-5 mr-3"
                v-text="ekran.opis"
              ></div>

              <div
                class="text-body-2 grey--text ml-5 mr-3"
                v-text="ekran.telefon"
              ></div>
              <div
                class="text-body-2 grey--text ml-5 mr-3"
                v-text="ekran.adres"
              ></div>

              <v-divider class="mt-2"></v-divider>

          
              <v-list-item v-if="parseFloat(ekran.ilosc) != 0">
                <v-list-item-avatar>
                  <v-icon class="secondary lighten-1" dark>
                    mdi-movie-open-outline
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Wyświetlam film</v-list-item-title>

                  <v-list-item-subtitle
                
                  >{{ekran.ilosc}} materiały</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

             

              <v-list-item v-else>
                <v-list-item-avatar>
                  <v-icon class="secondary lighten-1" dark>
                    mdi-timer-sand-empty
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Brak zawartości</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { onAuthStateChanged, getAuth, getIdToken } from "firebase/auth";

export default {
  name: "Screens",
  mounted() {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      // Check for user status

      if (user == null) {
        console.log("user is null");
      }
    });

    this.zaladujekrany();
  },

  computed: {
    procent: function () {
      if (this.ekrany.length > 0) {
        return (this.ekrany_online.length / this.ekrany.length) * 100;
      } else {
        return 0;
      }
    },

 
  posegregowaneekrany: function () {
   var tab = [];
   if(this.sortowanie == 1){




for (var i =0; i<this.ekrany.length;i++){


if(this.ekrany_online[this.ekrany[i].uid]){
tab.push(this.ekrany[i])
}
}


for (var j =0; j<this.ekrany.length;j++){


if(!this.ekrany_online[this.ekrany[j].uid]){
tab.push(this.ekrany[j])
}
}

    return tab


    }else if(this.sortowanie == 3){


for (var k =0; k <this.ekrany.length;k++){


if(!this.ekrany_online[this.ekrany[k].uid]){
tab.push(this.ekrany[k])
}

}



return tab


    }else{
    return this.ekrany
  }}



  },

  data: () => ({
    edycjadialog: false,
edycjanazwa: "",
edycjatelefon: "",
edycjaadres:"",
log_table: [],
edycjaopis:"",
edycjaurl: "",
edycjaloading: false,
    kolejka: [],
    edytujurl:false,
    sortowanie: 1,
    daneopis:null,
    do_wyboru:[],
    nowyurl:"",
    daneurl:null,
    kolejka_wybor:null,
    daneadres:null,
    danetelefon:null,
    refreshing:false,
    dialog_kolejka:false,
    ekrany: [],
    nazwaRules: [
      (v) => !!v || "Name ekranu jest wymagana",
      (v) => v.length > 3 || "Nazwa musi być dłuższa niż 3 znaku",
    ],
    opisRules: [(v) => !!v || "Opis ekranu jest wymagany"],
    emailRules: [
      (v) => !!v || "E-mail jest wymagany",
      (v) => /.+@.+/.test(v) || "E-mail musi być poprawny",
    ],
    telefonRules: [
      (v) => !!v || "Telefon jest wymagany",
      (v) => v.length >= 9 || "Telefon ma minium 9 znaków",
      (v) => /[0-9]/.test(v) || "Telefon musi być poprawny",
    ],
    adresRules: [
      (v) => !!v || "Adres jest wymagany",
      (v) => v.length >= 5 || "Adres musi mieć minimum 5 znaków",
    ],
 urlRules: [
      (v) => !!v || "Adres url jest wymagany",
      (v) => v.length >= 5 || "Adres musi mieć minimum 5 znaków",
    ],
    danedialog: false,
    danenazwa: "",
    wybranyzrzut: "",
    ekranurl:"",
    wybranyzrzutczas: "",
    wybranypotrzeba: null,
    danelogin: "",
    wybranyuid: "",
    danehaslo: "",
    utworzonoekran: false,
    nowylogin: "",
    nowehaslo: "",
    loadingekran: false,
    loadingkolejka:false,
    ekrannazwa: "",
    ekrantelefon: "",
    ekranopis: "",
    ekranadres: "",
    uszkodzone_ekrany: [],
    ekrany_online: {},
    links: ["Ekrany", "Studio", "Pomoc"],
    dialog: false,
  }),

  methods: {


      is_online(uid){

        return this.ekrany_online[uid] ? true : false;
      },

     async usunekran() {

           if(confirm('Czy napewno chcesz usunąć ekran?')){



console.log("usuwam ekran");
       try{
      const auth = getAuth();

      const token = await getIdToken(auth.currentUser);

       var postData = {
          uid: this.wybranyuid,
        };

        let axiosConfig = {
          headers: {
            Authorization: token,
          },
        };

        await axios.post(
          "https://adlandekrany.t1s.pl/usunekran",
          postData,
          axiosConfig
        );
      this.danedialog = false;
        this.zaladujekrany();


      } catch (e) {
        console.log(e);
      }

     }
    },




    async zaladujekrany() {
      const auth = getAuth();

      const token = await getIdToken(auth.currentUser);

      let axiosConfig = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(
        "https://adlandekrany.t1s.pl/ekrany",
        axiosConfig
      );
      this.ekrany = data.ekrany;
      this.ekrany_online = data.online;
      console.log(data);
    },

    async zglos_zrzut_ekranu() {
      try {
        const auth = getAuth();

        const token = await getIdToken(auth.currentUser);

        var postData = {
          uid: this.wybranyuid,
        };

        let axiosConfig = {
          headers: {
            Authorization: token,
          },
        };

        await axios.post(
          "https://adlandekrany.t1s.pl/zglos_zrzut_ekranu",
          postData,
          axiosConfig
        );

        this.wybranypotrzeba = true;
      } catch (e) {
        console.log(e);
      }
    },
 async zapiszurl() {
      try {
        const auth = getAuth();

        const token = await getIdToken(auth.currentUser);

        var postData = {
          uid: this.wybranyuid,
          url: this.nowyurl
        };

        let axiosConfig = {
          headers: {
            Authorization: token,
          },
        };

        await axios.post(
          "https://adlandekrany.t1s.pl/ustaw_url",
          postData,
          axiosConfig
        );

      this.utworzonoekran = false;
      this.danedialog = false;
      this.zaladujekrany();


      } catch (e) {
        console.log(e);
      }
    },

    async pobierz_ekran() {



      this.wybranyzrzut = null;
      this.wybranypotrzeba = false;
      try {
        const auth = getAuth();

        const token = await getIdToken(auth.currentUser);

        var postData = {
          uid: this.wybranyuid,
        };

        let axiosConfig = {
          headers: {
            Authorization: token,
          },
        };

        const { data } = await axios.post(
          "https://adlandekrany.t1s.pl/ekran",
          postData,
          axiosConfig
        );




        this.log_table = data.log;
        this.wybranypotrzeba = data.ekran[0]["potrzeba_foto"];


if(data.ekran[0]["foto"] != null && data.ekran[0]["foto"] != ""  ){


var r = await axios.get("https://adlandekrany.t1s.pl/"+data.ekran[0]["foto"],

 {  headers: {
            Authorization: token,
          },
          
           responseType:"arraybuffer"}
 
 
 
 );
var d = Buffer.from(r.data).toString('base64');

this.wybranyzrzut = `data:image/png;base64, ${d}`;


}else{

  this.wybranyzrzut = null;
}


        this.wybranyzrzutczas = data.ekran[0]["czas_foto"];




console.log("WPP")
        console.log(this.wybranypotrzeba);
        this.kolejka = data.kolejka;
        this.do_wyboru = data.do_wyboru;

      } catch (e) {
        console.log(e);
      }
    },

    async utworzekran() {
      if (this.$refs.form.validate()) {
        this.loadingekran = true;

        try {
          const auth = getAuth();

          const token = await getIdToken(auth.currentUser);

          var postData = {
            nazwa: this.ekrannazwa,
            opis: this.ekranopis,
            adres: this.ekranadres,
            telefon: this.ekrantelefon,
            url: this.ekranurl
          };

          let axiosConfig = {
            headers: {
              Authorization: token,
            },
          };

          const { data } = await axios.post(
            "https://adlandekrany.t1s.pl/nowyekran",
            postData,
            axiosConfig
          );
          this.loadingekran = false;
          this.utworzonoekran = true;
          this.nowylogin = data.login;
          this.nowehaslo = data.password;

          this.$refs.form.reset();
          this.zaladujekrany();
        } catch (e) {
          this.loadingekran = false;

          console.log(e);
        }
      }
    },



 async edytujekran() {
      if (this.$refs.formedycja.validate()) {
        this.edycjaloading = true;

        try {
          const auth = getAuth();

          const token = await getIdToken(auth.currentUser);

          var postData = {
            nazwa: this.edycjanazwa,
            opis: this.edycjaopis,
            adres: this.edycjaadres,
            telefon: this.edycjatelefon,
            url: this.edycjaurl,
            uid: this.wybranyuid
          };

          let axiosConfig = {
            headers: {
              Authorization: token,
            },
          };

          await axios.post(
            "https://adlandekrany.t1s.pl/edytujekran",
            postData,
            axiosConfig
          );
          this.edycjaloading = false;
 

          this.$refs.formedycja.reset();
          this.edycjadialog = false;
          this.danedialog = false;
          this.zaladujekrany();
        } catch (e) {
          this.edycjaloading = false;

          console.log(e);
        }
      }
    },



    async dodajkolejka() {

        this.loadingkolejka = true;

        try {
          const auth = getAuth();

          const token = await getIdToken(auth.currentUser);

          var postData = {
            uid: this.wybranyuid,
            material: this.kolejka_wybor,
           
          };

          let axiosConfig = {
            headers: {
              Authorization: token,
            },
          };

       await axios.post(
            "https://adlandekrany.t1s.pl/kolejka/dodaj",
            postData,
            axiosConfig
          );
          
          this.loadingkolejka = false;
         
          this.dialog_kolejka = false;
          this.kolejka_wybor = null;
          this.pobierz_ekran();
          this.zaladujekrany();

        } catch (e) {
          this.loadingkolejka = false;
          
          console.log(e);
        }
      
    },


    async usunkolejka(idd) {


        try {
          const auth = getAuth();

          const token = await getIdToken(auth.currentUser);

          var postData = {
            id: idd,
     
           
          };

          let axiosConfig = {
            headers: {
              Authorization: token,
            },
          };

       await axios.post(
            "https://adlandekrany.t1s.pl/kolejka/usun",
            postData,
            axiosConfig
          );
          
     
          this.pobierz_ekran();
          this.zaladujekrany();

        } catch (e) {
          
          console.log(e);
        }
      
    },



    async odswiez_ekran() {
this.refreshing = true;
        try {
          const auth = getAuth();

          const token = await getIdToken(auth.currentUser);

          var postData = {
            uid: this.wybranyuid,
     
           
          };

          let axiosConfig = {
            headers: {
              Authorization: token,
            },
          };

       await axios.post(
            "https://adlandekrany.t1s.pl/kolejka/aktualizuj",
            postData,
            axiosConfig
          );
          
     
          this.pobierz_ekran();
          this.refreshing = false;

        } catch (e) {
          

          this.refreshing = false;

        }
      
    },






  },

  sockets: {
   async zrzut_ekranu(data) {
      if ((data.uid = this.wybranyuid)) {


try{
 const auth = getAuth();

      const token = await getIdToken(auth.currentUser);


var r = await axios.get("https://adlandekrany.t1s.pl/"+data.adres,

 {  headers: {
            Authorization: token,
          },
          
           responseType:"arraybuffer"}
 
 
 
 );
var d = Buffer.from(r.data).toString('base64');

this.wybranyzrzut = `data:image/png;base64, ${d}`;

 this.wybranypotrzeba = false;
        this.wybranyzrzutczas = data.czas;


}catch(e){

        console.log(e);
      }
      }

       
      },
    
    are_online(data) {
      console.log("Online screens:");
      console.log(data);

      this.ekrany_online = data;
    },
  },
};
</script><style >
.shad {
  border-radius: 10px;
  box-shadow: -5px 5px 21px #c3bfc6, 5px -5px 21px #ffffff;
}
</style>